import React, { useState } from "react"
import {
  Card,
  Container,
  Accordion,
  useAccordionToggle,
  Row,
} from "react-bootstrap"
import { Section, SectionHeading, TextBlock } from "../../UI/Common"
import {
  accordionCls,
  chevronCls,
  cardCls,
  customToggleCls,
} from "./Faq.module.scss"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"

const Faq = ({ fieldGroupName, sectionHeading, description, accordion, config }) => {
  const { padding } = config || {}

  const [activeKey, setActiveKey] = useState("0")
  const [itemsToShow, setItemsToShow] = useState(3)

  const CustomToggle = ({ children, eventKey, handleClick, className }) => {
    const clickHandler = useAccordionToggle(eventKey, () => handleClick())
    return (
      <div className={className} onClick={clickHandler}>
        {children}
      </div>
    )
  }

  const showmore = () => setItemsToShow(accordion.length)
  const showless = () => setItemsToShow(3)

  return (
    <>
      <Section className={fieldGroupName} padding={padding}>
        <Container className="text-center">
          <SectionHeading
            text={sectionHeading}
            alignment="center"
            showUnderline={false}
          />
          {description && <TextBlock text={description} alignment="center" mode="muted" />}
          <Row className="justify-content-center pt-lg-4">
            <Accordion
              defaultActiveKey={"0"}
              activeKey={activeKey}
              className={accordionCls}>
              {accordion &&
                accordion.slice(0, itemsToShow).map((element, index) => {
                  return (
                    <Card key={index} className={cardCls}>
                      <CustomToggle
                        className={customToggleCls}
                        eventKey={index.toString()}
                        handleClick={() => {
                          activeKey === index
                            ? setActiveKey(null)
                            : setActiveKey(index.toString())
                        }}>
                        <h4 className="text-left m-0 ">{element.question}</h4>
                        <ArrowDown
                          aria-label="chevron-toggle"
                          width="24"
                          height="24"
                          className={`${
                            activeKey === index ? "rotate-r-180" : ""
                          } ${chevronCls}`}
                        />
                      </CustomToggle>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <TextBlock text={element.answer} alignment="start" className="px-4 text-left" />
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              {itemsToShow === 3 ? (
                <div className="p-3">
                  <u role="button" onClick={showmore}>
                    Show More
                  </u>
                </div>
              ) : (
                <div className="p-3">
                  <u role="button" onClick={showless}>
                    Show Less
                  </u>
                </div>
              )}
            </Accordion>
          </Row>
        </Container>
      </Section>
    </>
  )
}
// Faq.propTypes = {}

export default Faq
