import { graphql } from "gatsby"
import React from "react"

import Faq from "./Faq"

export const fragment = graphql`
  fragment FaqFragment on WpPage_Flexlayouts_FlexibleLayouts_Faq {
    fieldGroupName
    sectionHeading
    description
    accordion {
      answer
      question: questionText
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFFaq = ({ fieldGroupName, sectionHeading, description, accordion, config }) => (
  <Faq
    fieldGroupName={fieldGroupName}
    sectionHeading={sectionHeading}
    description={description}
    accordion={accordion}
    config={config}
  />
)
