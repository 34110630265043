// extracted by mini-css-extract-plugin
export var accordionCls = "Faq-module--accordionCls--4c9ac";
export var bgGrey100 = "Faq-module--bg-grey-100--e8241";
export var bgGrey150 = "Faq-module--bg-grey-150--cb1fe";
export var bgGrey200 = "Faq-module--bg-grey-200--f4d8b";
export var bgGrey300 = "Faq-module--bg-grey-300--496bf";
export var bgGrey400 = "Faq-module--bg-grey-400--6f37f";
export var bgGrey500 = "Faq-module--bg-grey-500--17747";
export var bgGrey600 = "Faq-module--bg-grey-600--850fb";
export var bgGrey700 = "Faq-module--bg-grey-700--84158";
export var bgGrey800 = "Faq-module--bg-grey-800--da38c";
export var bgGrey900 = "Faq-module--bg-grey-900--2fab6";
export var cardCls = "Faq-module--cardCls--8e901";
export var chevronCls = "Faq-module--chevronCls--2eec6";
export var customToggleCls = "Faq-module--customToggleCls--1991a";
export var descriptionCls = "Faq-module--descriptionCls--c4ee3";
export var textGrey100 = "Faq-module--text-grey-100--5d384";
export var textGrey150 = "Faq-module--text-grey-150--a9435";
export var textGrey200 = "Faq-module--text-grey-200--3ecf0";
export var textGrey300 = "Faq-module--text-grey-300--bad15";
export var textGrey400 = "Faq-module--text-grey-400--d1a89";
export var textGrey500 = "Faq-module--text-grey-500--5e4d9";
export var textGrey600 = "Faq-module--text-grey-600--4f661";
export var textGrey700 = "Faq-module--text-grey-700--e5d59";
export var textGrey800 = "Faq-module--text-grey-800--447d3";
export var textGrey900 = "Faq-module--text-grey-900--4755a";